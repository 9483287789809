<template>
  <div id="app">
    <div v-if="loading || noenv" style="margin-top: 30vh">
      <b-spinner label="Spinning" v-if="loading" />
      <div v-if="noenv">
        <div>
          <b-icon
            font-scale="3.5"
            icon="exclamation-triangle"
            class="border border-warning rounded p-2"
            variant="warning"
          />
        </div>
        <div class="h2 mt-3">ENVIRONMENT NOT FOUND</div>
        <b-button class="mt-3 text-white" @click="reload()" variant="warning"
          >RELOAD</b-button
        >
      </div>
    </div>
    <router-view v-else />
  </div>
</template>
<script>
import UAParser from "ua-parser-js"
export default {
  data() {
    return {
      loading: true,
      noenv: false,
    }
  },
  name: "App",
  methods: {
    randomAlphaNumeric(length) {
      var result = ""
      var chars =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
      for (var i = length; i > 0; --i)
        result += chars[Math.round(Math.random() * (chars.length - 1))]
      return result
    },
    reload() {
      location.reload()
    },
    compileDeviceInfo() {
      const userAgentInfo = new UAParser().getResult()
      return {
        browser: userAgentInfo.browser.name,
        browserVersion: userAgentInfo.browser.version,
        os: userAgentInfo.os.name,
        osVersion: userAgentInfo.os.version,
        screen: {
          width: screen.width,
          height: screen.height,
        },
      }
    },
  },
  async created() {
    const environmentID = "jessicasmith-tv"
    if (environmentID) this.$store.commit("saveEnvironmentID", environmentID)

    const queryParams = {}
    for (const key in this.$router.currentRoute.query) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.$router.currentRoute.query.hasOwnProperty(key)) {
        const item = this.$router.currentRoute.query[key]

        if (key === "fbclid") queryParams[key] = item
        else if (key === "gclid") queryParams[key] = item
        else if (!key.includes("utm")) continue

        queryParams[key] = item
      }
    }

    if (Object.keys(queryParams).length) {
      this.$store.commit("queryParams", queryParams)
    }

    this.$store.commit("saveSessionID", "s" + this.randomAlphaNumeric(15))

    const authToken = this.$cookies.get("authToken")
    if (authToken) {
      this.$store.commit("mergeUser", {
        authToken,
        userState: "signedIn",
      })
    } else {
      let anonymousUserID = this.$cookies.get("anonymousUserID")
      if (!anonymousUserID) {
        anonymousUserID = "u" + this.randomAlphaNumeric(15)
        // $cookies.set(keyName, value[, expireTimes[, path[, domain[, secure[, sameSite]]]]])
        this.$cookies.set(
          "anonymousUserID",
          anonymousUserID,
          "999d",
          "/",
          null,
          null,
          "Lax",
        )
      }

      this.$store.commit("mergeUser", {
        userInfo: {
          anonymousUserID,
        },
        userState: "anonymous",
      })
    }
    const userDevice = this.compileDeviceInfo()
    this.$store.commit("mergeUser", { userDevice })
    await this.$store.dispatch("startSession")

    await this.$store.dispatch("fetchEjson", environmentID)
    if (!(this.$store.state.eJSON && this.$store.state.eJSON.pages))
      this.noenv = true
    this.loading = false

    await this.$store.dispatch("fetchDeliveryDomain")
    await this.$store.dispatch("smartFetch", {
      force: true,
      endpoint: "content/contentForEnvironment",
      body: {
        pagination: {
          sortBy: "content.whenLastUpdated",
          descending: true,
        },
        nameSpace: "blueprint",
        environmentID: environmentID,
        includeFields: ["byLine", "contentName", "shortBlurb"],
      },
    })

    window.LOADTIMER = Date.now()
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
body {
  background-color: #18191a !important;
  color: white !important;
}
input {
  background-color: #18191a !important;
  color: white !important;
  border-color: rgb(179, 179, 179) !important;
}
</style>
