<template>
  <div class="p-5">
    <div class="mt-5">
      <b-img
        :src="$store.getters.environmentLogoURL"
        fluid
        onerror="this.style.display='none'"
        class="mb-5"
        style="max-height: 40vh; max-width: 400px"
      />
    </div>
    <b-form
      @submit.stop.prevent="onSubmit"
      class="w-75 text-left"
      style="margin: auto"
    >
      <b-form-group label="Email" label-for="userEmail-input">
        <b-form-input
          id="userEmail-input"
          name="userEmail-input"
          v-model="$v.form.userEmail.$model"
          :state="validateState('userEmail')"
          aria-describedby="userEmail-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="userEmail-feedback"
          >Please enter a valid email</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group label="Password" label-for="userPassword-input">
        <b-form-input
          id="userPassword-input"
          name="userPassword-input"
          v-model="form.userPassword"
          aria-describedby="userPassword"
          type="password"
        ></b-form-input>
        <b-form-invalid-feedback id="userPassword-feedback"
          >Password is Required</b-form-invalid-feedback
        >
      </b-form-group>

      <div class="text-center">
        <b-button
          type="submit"
          variant="outline"
          :style="{
            color: 'white',
            borderColor: 'white',
            right: ' 10px !important',
          }"
          >Sign In</b-button
        >
        <b-button
          variant="outline"
          class="ml-2"
          :style="{
            color: 'white',
            borderColor: 'white',
            right: ' 10px !important',
          }"
          @click="resetPassword"
          >Forgot Password ?</b-button
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, email } from "vuelidate/lib/validators"

export default {
  mixins: [validationMixin],
  name: "SignIn",
  data() {
    return {
      passwordError: "",
      form: {
        userEmail: null,
        userPassword: null,
      },
    }
  },
  validations: {
    form: {
      userEmail: {
        required,
        email,
      },
    },
  },
  methods: {
    async updateLocalFetchedLibrary() {
      await this.$store
        .dispatch("smartFetch", {
          force: true,
          endpoint: "content/contentForEnvironment",
          body: {
            pagination: {
              sortBy: "content.whenLastUpdated",
              descending: true,
            },
            nameSpace: "blueprint",
            environmentID: this.$store.state.environmentID,
            includeFields: ["byLine", "contentName", "shortBlurb"],
          },
        })
        .finally(() => {
          console.log("library updated")
        })
    },
    validateState(userEmail) {
      const { $dirty, $error } = this.$v.form[userEmail]
      return $dirty ? !$error : null
    },
    async signIn() {
      const res = await this.$api("user/signIn", {
        userInfo: this.form,
      })
      console.log("signIn", res)

      if (res?.data.success) {
        this.$store.commit("userIdentified", res.data.data)
        this.updateLocalFetchedLibrary()
      } else {
        if (res?.data.messageCode === "Passwords do not match") {
          this.passwordError = res.data.messageCode
        } else {
          this.$bvToast.toast("Email or Password incorrect", {
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: false,
          })
        }
      }
    },

    async resetPassword() {
      //CHECK IF EMAIL IS VALID
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return this.$bvToast.toast("Please enter a valid email", {
          variant: "danger",
          autoHideDelay: 4000,
          appendToast: false,
        })
      }

      // CHECK IF EMAIL EXIST ON THE DB
      const checkEmailRes = await this.$apiPublic("userAuth/checkEmail", {
        userInfo: {
          userEmail: this.form.userEmail,
        },
      })
      if (!checkEmailRes.data.success) {
        return this.$bvToast.toast(
          "Sorry, this email doesn't seem to be linked with an account",
          {
            variant: "danger",
            autoHideDelay: 4000,
            appendToast: false,
          },
        )
      }

      // SEND FORGOT PWD EMAIL
      const res = await this.$api("user/requestPasswordReset", {
        userEmail: this.form.userEmail,
        environmentID:
          this.$store.getters.passwordResetEnvironment ||
          this.$store.state.environmentID,
      })

      if (res.data.success) {
        this.$bvToast.toast(
          "Your reset password email has been sent. Please check your inbox for instructions!",
          {
            variant: "success",
            autoHideDelay: 4000,
            appendToast: false,
          },
        )
        this.$apiPublic("remember/it", {
          logType: "userClickedForgotPassword",
          description: "User clicked forgot password.",
          userEmail: this.form.userEmail,
          context: "session",
          environmentID: this.$store.state.environmentID,
        })
      } else {
        this.$bvToast.toast(
          res.data.message || "Something went wrong, please try again.",
          {
            variant: "success",
            autoHideDelay: 4000,
            appendToast: false,
          },
        )
      }
    },
    async onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      await this.signIn()
    },
  },
}
</script>
