import axios from "axios"
axios.defaults.baseURL = process.env.VUE_APP_MY_BLAZAR_HOST

const nameSpace = "bali"
const appVersion = "0.01"
const origin = "tv-library"

async function performRequest(req) {
  let res

  try {
    const options = {
      url: req.route,
      data: req.body,
      method: "POST",
    }
    res = await axios(options)
    console.log(res)
  } catch (err) {
    console.error(err)
  }

  return res
}

export function api(route, request, publicApi = false, state) {
  return performRequest({
    route: publicApi ? route : nameSpace + "/" + route,
    body: {
      payload: {
        ...request,
      },
      sessionInfo: {
        origin,
        appVersion,
        nameSpace,
        sessionID: state.sessionID,
        environmentID: state.environmentID,
        userInfo: state.user.userInfo,
        userDevice: state.user.userDevice,
        referrer: location.host,
        userLocation: state.user.userLocation,
      },
      queryParams: state.queryParams,
      sessionToken: state.user.sessionToken,
      authToken: state.user.authToken,
    },
  })
}
