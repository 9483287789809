import Vue from "vue"
import Vuex from "vuex"
import { api } from "../utils/api"
import LogRocket from "logrocket"

LogRocket.init("dwlu0o/blueprint")
Vue.use(Vuex)
export const store = new Vuex.Store({
  state: {
    user: {
      userInfo: {},
      userDevice: {},
      userLocation: {},
      userState: "unknown",
      authToken: "",
      sessionToken: "",
    },
    eJSON: {},
    contentID: "",
    contentMetaData: {},
    contentSearch: "",
    environmentID: "",
    deliveryDomain: "https://vault2.platformpurple.com",
    environmentInfo: {},
    queryParams: null,
    cdnPreviewRoute: "/static/previews/",
    sessionID: "",
    contentForEnvironment: [],
    systemDesign: {
      defaultColor: "#aaaaaa",
    },
  },
  getters: {
    environmentLogoURL: (state) => {
      const logos = state.eJSON?.globalProps?.logos
      return logos.length
        ? `${state.deliveryDomain}${logos[logos.length - 1].href}`
        : ""
    },
    brandColor: (state) => {
      const brandColor = state.eJSON.globalProps.design.brandColor
      const environmentColors = state.eJSON.globalProps.design.colors
      return environmentColors[brandColor] &&
        environmentColors[brandColor].color
        ? environmentColors[brandColor].color
        : state.systemDesign.defaultColor
    },
    fullScreenAsDefault: (state) => {
      return state.eJSON.globalProps?.tv?.alwaysLaunchVideoInFullScreen
    },
    passwordResetEnvironment: (state) => {
      return state.eJSON.globalProps?.passwordResetEnvironment 
    },
  },
  mutations: {
    async signOut(state) {
      Vue.$cookies.remove("authToken")
      location.replace(location.origin + "?e=" + state.environmentID)
    },
    updateContentSearch(state, contentSearch) {
      state.contentSearch = contentSearch
    },
    userIdentified(state, resData) {
      console.log("userIdentified", resData)
      state.user.userState = "signedIn"
      state.user.userInfo = resData.userInfo
      state.user.authToken = resData.authToken
      state.user.sessionToken = resData.sessionToken

      Vue.$cookies.set(
        "authToken",
        resData.authToken,
        "999d",
        "/",
        null,
        null,
        "Lax",
      )
      Vue.$cookies.remove("anonymousUserID")
      LogRocket.identify(resData.userInfo.userEmail, {
        name: resData.userInfo.firstName + " " + resData.userInfo.lastName,
        userEmail: resData.userInfo.userEmail,
      })
    },
    saveEnvironmentID(state, environmentID) {
      state.environmentID = environmentID
    },
    queryParams(state, value) {
      state.queryParams = value
    },
    saveContentID(state, contentID) {
      state.contentID = contentID
    },
    saveSessionID(state, sessionID) {
      state.sessionID = sessionID
    },
    mergeUser(state, mergeData) {
      let src = mergeData
      let dest = state.user
      if (src === undefined) return dest
      if (dest === undefined) return src
      Object.keys(src).forEach(function(key) {
        dest[key] = src[key]
      })
      state.user = dest
    },
    saveDeliveryDomain(state, deliveryDomain) {
      state.deliveryDomain = deliveryDomain
    },
    saveEjson(state, eJSON) {
      state.eJSON = eJSON
    },
    updateEnvironmentInfo(state, environmentInfoObj) {
      state.environmentInfo = environmentInfoObj
    },
    contentForEnvironment(state, data) {
      state.contentForEnvironment = data
    },
    saveContentMetaData(state, contentMetaData) {
      state.contentMetaData = contentMetaData
    },
  },
  actions: {
    async fetchContentMetadata({ state }, contentData) {
      const response = await api(
        "content/metadataForContentIDs",
        {
          contentIDs: contentData.contentIDs,
          includeFields: contentData.includeFields || [
            "byLine",
            "contentName",
            "shortBlurb",
            "longDescriptionHtml",
            "transactionInfo",
            "tagIds",
            "tagString",
            "xJSON",
            "projectID",
          ],
        },
        false,
        state,
      )
      console.log(response)

      return response.data
    },
    async smartFetch({ state }, request) {
      const keyPath = request.endpoint.slice(
        request.endpoint.lastIndexOf("/") + 1,
        request.endpoint.length,
      )
      console.log("keyPat", keyPath)
      console.log("smartFetch:", request)
      // check if we already have the data
      const alreadyHave = !(
        !state[keyPath] ||
        (typeof state[keyPath] === "object" &&
          !Object.keys(state[keyPath]).length)
      )
      if (!alreadyHave || request.force) {
        // first, put some data in this keyPath in $store.state so if this is called again before the API responds we don't get the data twice
        Vue.set(state, keyPath, { content: null })
        const res = await api(request.endpoint, request.body, false, state)
        console.log(res)
        Vue.set(state, keyPath, res.data)
      }
    },
    async startSession({ commit, state }) {
      const res = await api(
        "user/startSession",
        { includeUserLocationInfo: true },
        false,
        state,
      )
      if (res.data.success) {
        commit("mergeUser", res.data.data)
      }
    },
    async fetchDeliveryDomain({ commit, state }) {
      const res = await api("cdn/deliveryDomain", {}, false, state)
      commit("saveDeliveryDomain", res.data.data.deliveryDomain)
    },
    async fetchEjson({ commit, state }, environmentID) {
      const res = await api(
        "environments/environments/eJSON",
        { environmentID },
        false,
        state,
      )
      if (res.data.success) {
        var pages = res.data?.data?.pages?.filter(
          (el) => el.meta.path === "/library" || el.meta.path.includes("play"),
        )
        const eJSON = { ...res.data.data, pages }
        commit("saveEjson", eJSON)
      }
      const environmentInfo = await api(
        "environments/environments/environmentInfo",
        {
          environmentID,
        },
        false,
        state,
      )
      if (environmentInfo.data.success) {
        environmentInfo.data.data.eJSON.pages = pages
        commit("updateEnvironmentInfo", environmentInfo.data.data)
      }
    },
  },
})
